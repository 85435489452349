<template>
	<label class="check-box-wrap">
        <input type="checkbox" :value="value" @change="onCheckChanged" :checked="isCheck">
		<span class="check-icon"></span>
        <span class="check-label"><slot/></span>
	</label>
</template>

<script>

	export default {
		name: 'CheckBox',
        model: {
            prop: 'formValue',
            event: 'input'
        },
		data() {
			return {
				
			}
		},
        props: {
            value: [String,Number],
            formValue: {
                type: Array,
                default: function() {
                    return []
                }
            }
        },
        computed: {
            isCheck() {
                return this.formValue.indexOf(this.value) >= 0;
            }
        },
		methods: {  
            onCheckChanged(e) {
                const idx = this.formValue.indexOf(this.value);
                if(e.target.checked) {
                    if(idx < 0) {
                        this.$emit('input', this.formValue.concat([this.value]));
                    }
                } else {
                    if(idx >= 0) {
                        this.$emit('input', this.formValue.filter(item => item !== this.value));
                    }
                }
            }
		},
	}
</script>

<style lang="scss" scoped>
	.check-box-wrap {
        input[type=checkbox]{
            display: none;
        }

        .check-label, .check-icon{
            display: inline-block;
            vertical-align: middle;
        }

        .check-icon {
            width: 14px;
            height: 14px;
            border: 1px solid #EA5504;
            border-radius: 2px;
            position: relative;
        }

        .check-label {
            margin-left: 5px;
        }

        input[type=checkbox]:checked + .check-icon{
            background-color: #EA5504;

            &::after {
                content: ' ';
                width: 3px;
                height: 7px;
                border-bottom: 2px solid white;
                border-right: 2px solid white;
                position: absolute;
                left: 3px;
                top: 1px;
                transform: rotate(45deg);
                transform-origin: center;
            }
        }
    }
</style>
