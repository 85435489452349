 
import VueRouter from 'vue-router'
 
import PageIndex from '../components/PageIndex.vue'
import SucceedPage from '../components/SucceedPage'
 
export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/apply",
            name:'PageIndex',
            component: PageIndex
        }, {
            path:'/applySuccess',
            name:'SucceedPage',
            component:SucceedPage
        }
    ]
})