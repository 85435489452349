<template>
	<div class="container">
		<div class="logo">
			<img src="../assets/succeed.png" mode="aspectFit" style="width: 100%; height: 100%;" />
		</div>
		<div class="text-box">
			<div class="text-item">
				感谢您的申请
			</div>
			<div class="item-item-t">
				申请成功！请等待人员审核
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'SucceedPage',
		data() {
			return {
				// orderCode: undefined
			}
		},
		methods: {

		},
		onLoad() {
			
		},
	}
</script>

<style lang="scss" scoped>
	.logo {
		width: 90px;
		height: 90px;
		margin: 200px auto 0;
	}

	.text-box {
		text-align: center;
		margin-top:50px;
		.text-item {
			height: 22px;
			margin-top: 48px;
			font-weight: bold;
			font-size: 16px;
			color: #333333;
			line-height: 22px;
		}

		.item-item-t {
			height: 17px;
			margin-top: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #999999;
			line-height: 17px;
		}
	}
</style>
