<template>
  <div class="hello">
    <h3 class="top">快瑞ERP-商户合作申请</h3>
    <div class="content">

      <!-- 基础信息 -->
      <div>
        <div class="basics">
          <div style="width: 2px;height: 10px;background: #EA5504;margin-right: 6px;"></div>
          <div class="">基础信息</div>
        </div>
        <div class="message">
          <form action="form">
            <div class="inputitem">姓名<i style="color:red">*</i> : <input type="text" v-model="form.partnerName"> </div>
            <div class="inputitem">手机号码<i style="color:red">*</i> : <input type="number" v-model="form.mobile"></div>
            <div class="inputitem">企业名称<i style="color:red">*</i> : <input type="text" v-model="form.companyName"></div>
            <div class="inputitem">平台信息 : <input type="text" v-model="form.platform"></div>
          </form>
        </div>
      </div>

      <!-- 营业执照 -->
      <div>
        <div class="basics">
          <div style="width: 2px;height: 10px;background: #EA5504;margin-right: 6px;"></div>
          <div class="">营业执照<i style="color:red">*</i></div>
        </div>

        <div class="addphoto">

          <input ref="uploadImageInput" @change="handleFileChange" type="file" name="file" multiple="true"
            class="upload-image" />
          <img v-if="credentials" :src="credentials " alt="" class="add_image" @click="onAddImage" key="1">
          <img v-else src="../assets/addimg.png" alt="" class="add_image" @click="onAddImage" key="2">
        </div>
      </div>
      <!-- 选择产品 -->
      <div>
        <div class="basics">
          <div style="width: 2px;height: 10px;background: #EA5504;margin-right: 6px;"></div>
          <div class="">您感兴趣的产品(可多选)<i style="color:red">*</i></div>
        </div>
        <div class="selet-box">
          <div class="selet">
            <!-- <div class="selet-item" v-for="(item, index) in slectList" :key="index">
              <input type="checkbox" :value="item.id" v-model="form.interest">
              <label for=""> {{ item.name }}</label>
            </div> -->
            <div class="selet-item" v-for="(item, index) in slectList" :key="index">
              <CheckBox v-model="form.interest" :value="item.id">{{item.name}}</CheckBox>
            </div>
          </div>
        </div>
      </div>

      <!-- 描述 -->
      <div class="described">
        <div class="basics">
          <div style="width: 2px;height: 10px;background: #EA5504;margin-right: 6px;"></div>
          <div class="">描述</div>
        </div>

        <div>
          <textarea v-model="form.description" name="" id="" cols="50" rows="10" class="textare"
            placeholder="请简要描述您的业务场景说明或其它需求"></textarea>
        </div>

      </div>
    </div>
    <div class="decollator"></div>
    <div class="bottom">
      <button class="btn-Submit" @click="handleSubmit">提交</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import weui from 'weui.js';
import CheckBox from './CheckBox';
export default {
  name: 'PageIndex',
  props: {
    msg: String
  },
  components: {
    CheckBox
  },
  data() {

    return {
      credentials: '',
      form: {
        partnerName: '',
        mobile: '',
        companyName: '',
        platform: '',
        interest: [],//兴趣
        description: '',//描述
      },
      slectList: [
        {
          name: '小程序商城',
          id: 1
        },
        {
          name: '供应链服务',
          id: 2
        },
        {
          name: '聚合供应链',
          id: 3
        },
        {
          name: '采购渠道商',
          id: 4
        },
        {
          name: '供应链渠道商',
          id: 5
        },
        {
          name: '其他',
          id: 6
        },
      ]
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if(!file) return;
      const formData = new FormData();
      formData.append('file', file);
      const loading = weui.loading('请稍等...');
      axios.post('/app/api/v1/partner/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        console.log(res.data.data.url)
        this.credentials = res.data.data.url
      }, err => {
        // 出现错误时的处理
        console.log(err)
      }).finally(() => {
        loading.hide();
      })
    },

    onAddImage() {
      this.$refs.uploadImageInput.click();
    },

    handleSubmit() {
      console.log(this.form)
      this.infoSunbmit();
    },
    infoSunbmit() {
      const loading = weui.loading('请稍等...');
      axios.post('/app/api/v1/partner/apply', {
        partnerName: this.form.partnerName,
        mobile: this.form.mobile,
        companyName: this.form.companyName,
        appName: this.form.platform,
        interest: this.form.interest.join(','),
        description: this.form.description,
        businessLicenseUrl: this.credentials
      }).then(res => {
        if (res.data.code == 200) {
          this.$router.replace('/applySuccess')
        } else {
          weui.alert(res.data.msg);
        }
      }).finally(() => {
        loading.hide();
      })
    }
  }
}
</script>

<style scoped>
.top {
  padding: 20px 0;
  text-align: center;
}
.content{
  font-size: 14px;
}
.basics {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  background: #F8F8F8;
  height: 50px;
}

.inputitem {
  padding: 16px 20px;
  border-bottom: 1px solid #F3F4F5;
}

.selet {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.selet-item {
  padding-top: 20px;
  width: 30%;
}

input {
  border: none;
  outline: none;
  background: none;
}

.addphoto {
  padding: 20px;
}

.textare {
  width: 100%;
  border: none;
  outline: none;
  padding: 16px 20px;
}

.decollator {
  background: #F8F8F8;
  height: 30px;
}

.bottom {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.btn-Submit {
  border: none;
  background: none;
  width: 356px;
  height: 50px;
  line-height: 50px;
  background: #EA5504;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
}

.upload-image {
  display: none;
}

.add_image {
  width: 100px;
  height: 100px;
}
</style>
